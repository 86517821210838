import React, { useEffect, useState } from "react";
import { StructuredText } from "react-datocms";
import store from "@/redux/store";
import { useDispatch } from "react-redux";
import Link from "next/link";
import dynamic from "next/dynamic";

const ImageUI = dynamic(() => import("@/common/UI/Image"));
const MetaTags = dynamic(() => import("@/common/MetaTags"));

import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";

// Import graphQL query variable
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";

export default function Custom404({ labelsConfigData }) {
  const [siteConfig, setSiteConfig] = useState({});
  const [labelsList, setLabelsList] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabelsConfigsData(labelsConfigData));
    setSiteConfig(labelsConfigData?.siteConfig);
    setLabelsList(labelsConfigData?.labelsList);
  }, [dispatch, labelsConfigData]);

  return (
    <>
      <MetaTags />
      <section className="min-h-50 flex items-center justify-center pt-28 md:pt-10 pb-28 md:pb-10 md:min-h-50 lg:min-h-50 xl:min-h-75 relative">
        <div className="z-0">
          {siteConfig?.errorPageBackground?.url && (
            <ImageUI
              src={siteConfig?.errorPageBackground?.webp}
              alt={siteConfig?.errorPageBackground?.alt || "bg"}
              className="h-full object-cover object-center"
              layout="fill"
              priority={true}
              loading="eager"
              unoptimized={true}
            />
          )}
        </div>
        <div className="max-w-4xl mx-auto text-center z-[1] text-white w-full">
          <h1 className="h1-web font-bold text-center pb-2">
            {siteConfig?.missingPageTitle}
          </h1>
          {siteConfig?.missingPageDescription &&
            (typeof siteConfig?.missingPageDescription == "string" ? (
              <p className="h3-web block font-light">
                {siteConfig?.missingPageDescription}
              </p>
            ) : (
              <div className="h3-web block font-light">
                <StructuredText data={siteConfig?.missingPageDescription} />
              </div>
            ))}

          <div className="text-center">
            <Link
              href="/"
              className="inline-block mt-10 uppercase tracking-semi font-bold h5-web border-2 border-white py-2 px-5  text-white hover:text-white rounded-full"
            >
              {labelsList?.backHome}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export async function getStaticProps({ preview = false, locale = "it" }) {
  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, { lang: locale })
  );

  return {
    props: {
      preview,
      labelsConfigData: labelsConfigData || {},
    },
  };
}
